import React from 'react'
import './assets/css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Routing from './Routes/Routing.js';

function App() {
  return (
    <Routing/>
  );
  
}

export default App;
