import React from 'react'

function ResetPassword() {
  return (
    <div>
      Reset Password
    </div>
  )
}

export default ResetPassword
